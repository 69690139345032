/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  DatePicker,
  Popconfirm,
  Select,
  notification,
} from 'antd';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { TaskHelpers } from 'ontraccr-common';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import {
  costCodeIdTransformer, getLinkedValues, getRelevantAttributeFields, noIdTransformer,
} from 'ontraccr-common/lib/formFields/Attributes';
import { DATATYPES } from 'ontraccr-common/lib/Definitions';
import { useDispatch, useSelector } from 'react-redux';

import { getPopupRef, isNullOrUndefined } from '../../../../helpers/helpers';
import OnTraccrNumberInput from '../../../../common/inputs/OnTraccrNumberInput';
import OnTraccrTextInput from '../../../../common/inputs/OnTraccrTextInput';
// eslint-disable-next-line import/no-cycle
import TimeEntryTableHoursInput from './TimeEntryTableHoursInput';
import {
  getRelevantDropdownValues,
  momentToLuxon,
  simpleEquality,
} from './TimeEntryTable.helpers';
import TimeEntryTableDateTimeInput from './TimeEntryTableDateTimeInput';
import TimeEntryTableDropdownInput from './TimeEntryTableDropdownInput';
import { UNPHASED, getPhaseOptions } from '../../../../clock/ManualEntry/manualEntryHelpers';
import { getSubContractsWithTotalChangesMap } from '../../../../projects/ProjectScheduleOfValues/helpers';
import { getSubContractDetails } from '../../../state/forms.actions';

/**
 *
 * @param {*} props
 * @param {string} props.fieldType - Type of field being rendered
 * @param {string} props.inputType - Type of input to render
 * @param {string} props.title - Title of popover
 * @param {string} props.value - Initial value of input
 * @param {function} props.displayFormatter - Function to format value for display
 * @param {object} props.inputStyle - Style object to apply to input
 * @param {object} props.task - Task object
 * @param {timezone} props.timezone - Task timezone
 * @param {string} props.property - Property of task to update
 * @param {function} props.areEqual - Function to determine if input value is equal to initial value
 * @param {string} props.defaultValue - Default value to use if input is empty
 * @param {array} props.rules - Array of validation rules
 * @param {string} props.width - Width of input
 * @param {boolean} props.readOnly - Whether input is read only
 * @param {function} props.onUpdate - (entryId, payload) => void
 * @param {function} props.getSelectOptions - Accepts all props from  selectOptionProps and task;
 *  outputs an option list for Select
 * @param {object} props.selectOptionProps - Complete list of props for all variants of
 * getSelectOptions
 * @returns
 */
function TimeEntryTableInput(props) {
  const {
    fieldType = 'text',
    inputType = 'text',
    title,
    value: initialValue,
    displayFormatter = ({ value }) => value,
    inputStyle,
    task,
    timezone = DateTime.local().zoneName,
    property,
    areEqual,
    getSelectOptions = null,
    selectOptionProps = {},
    customDropdownProps = {},
    defaultCustomData,
    defaultValue,
    rules = [],
    width: inputWidth,
    readOnly = false,
    onUpdate,
    configProps = {},
    responding = false,
  } = props;

  const now = DateTime.local().toMillis();

  const {
    customData,
    date = TaskHelpers.formatDate(now),
    endTime,
    hourBased,
    id,
    startTime,
    breakEndTime,
    breakStartTime,
    costcodeId,
    divisionId,
    doubleOTEndTime,
    doubleOTStartTime,
    note,
    otEndTime,
    otStartTime,
    phaseId,
    projectId,
    type,
    userId,
  } = task;

  const {
    projectIdMap = {},
    costcodeIdMap = {},
    userIdMap = {},
    bucketIdMap = {},
    equipmentTypeMap = {},
    settings = {},
    scheduleOfValues = {},
    subContractMap = {},
    subContractCOMap = {},
    divisions = [],
  } = customDropdownProps;

  const dispatch = useDispatch();

  const equipment = useSelector((state) => state.equipment.equipment);
  const vendors = useSelector((state) => state.vendors.vendors);
  const globalAddressBooks = useSelector((state) => state.contacts.globalAddressBooks);
  const projectEquipment = useSelector((state) => state.equipment.projectEquipment);
  const customers = useSelector((state) => state.customers.customers);

  const divisionSet = useMemo(() => new Set(divisions), [divisions]);
  const selectedUser = useMemo(() => userIdMap[userId], [userId, userIdMap]);

  const popconfirmRef = useRef(null);
  const containerRef = useRef(null);
  const [showPopover, setShowPopover] = useState(false);
  const [showDisplay, setShowDisplay] = useState(true);
  const [inputValue, setInputValue] = useState(initialValue);
  const [inputOpen, setInputOpen] = useState(false);
  const payablesSubContractMap = useSelector((state) => state.forms.subContractMap);

  const luxonDate = useMemo(() => {
    const parsedDate = TaskHelpers.getTaskDate(task);
    if (!parsedDate?.isValid) {
      return DateTime.local();
    }
    return parsedDate;
  }, [task]);

  const isReadOnly = readOnly;

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  const switchToDisplayMode = () => {
    setShowDisplay(true);
    setShowPopover(false);
  };

  const onCancel = () => {
    switchToDisplayMode();
    setInputValue(initialValue);
  };

  // Dynamic Attribute Logic

  const relevantIds = useMemo(() => (
    getRelevantDropdownValues(customData, { multiple: true })
  ), [customData]);

  const subContractsWithTotalChanges = useCallback((dType, linkedProjectIds) => {
    if (dType !== DATATYPES.SubContract) return [];
    return getSubContractsWithTotalChangesMap({
      projectIds: linkedProjectIds,
      subContractMap,
      subContractCOMap,
    });
  }, [subContractMap, subContractCOMap]);

  useEffect(() => {
    if (fieldType !== 'attribute') return;
    const linkedPayableSubContractIds = relevantIds?.payableSubContractIds;
    if (linkedPayableSubContractIds?.length) {
      dispatch(getSubContractDetails(linkedPayableSubContractIds));
    }
  }, [relevantIds, fieldType]);

  const getRelevantDataValues = useCallback((dType, linkedProjectIds) => {
    switch (dType) {
      case DATATYPES.Customer: return [customers, relevantIds?.customerIds ?? []];
      case DATATYPES.Contact: return [globalAddressBooks, relevantIds?.contactIds ?? []];
      case DATATYPES.ProjectContract: return [scheduleOfValues, []];
      case DATATYPES.Project: return [projectIdMap, relevantIds?.projectIds ?? []];
      case DATATYPES.Vendor: return [vendors, relevantIds?.vendorIds ?? []];
      case DATATYPES.Costcodes: return [costcodeIdMap, relevantIds?.costcodeIds ?? []];
      case DATATYPES.User: return [userIdMap, relevantIds?.userIds ?? []];
      case DATATYPES.SubContract: return [
        subContractsWithTotalChanges(dType, linkedProjectIds),
        relevantIds?.subContractIds ?? [],
      ];
      case DATATYPES.Buckets: return [bucketIdMap, []];
      case DATATYPES.PayableSubContracts: return [payablesSubContractMap, []];
      default: return [{}, []];
    }
  }, [
    customers,
    globalAddressBooks,
    scheduleOfValues,
    projectIdMap,
    vendors,
    costcodeIdMap,
    userIdMap,
    subContractsWithTotalChanges,
    bucketIdMap,
    payablesSubContractMap,
    relevantIds,
  ]);

  const handleInputParsing = () => {
    let parsedValue = inputValue;
    const parsedCustomData = { ...(customData ?? defaultCustomData) };

    if (fieldType === 'date') {
      if (!moment.isMoment(inputValue)) {
        Sentry.withScope(() => {
          Sentry.captureException(new Error('Invalid moment date object provided'));
        });
        return { parsedValue, parsedCustomData };
      }

      parsedValue = inputValue.format('YYYY-MM-DD');
    }

    return { parsedValue, parsedCustomData };
  };

  const handlePropertyInteractions = ({ updatedValues, parsedValue }) => {
    const updatedValuesRef = updatedValues;

    if (property !== 'date') {
      updatedValuesRef.date = luxonDate.toSQLDate();
    }

    if (property === 'date') {
      if (!moment.isMoment(inputValue)) {
        Sentry.withScope(() => {
          Sentry.captureException(new Error('Invalid moment date object provided'));
        });
        return;
      }

      const luxDate = momentToLuxon(inputValue);

      const millisObj = {
        startTime,
        endTime,
        otStartTime,
        otEndTime,
        doubleOTStartTime,
        doubleOTEndTime,
        breakStartTime,
        breakEndTime,
      };

      Object.entries(millisObj).forEach(([key, value]) => {
        if (value) {
          const luxMillis = DateTime.fromMillis(value);
          const newMillis = luxDate.set({
            hour: luxMillis.hour,
            minute: luxMillis.minute,
          }).toMillis();
          updatedValuesRef[key] = newMillis;
        }
      });
    }

    if (property === 'projectId') {
      updatedValuesRef.costcodeId = null;
      updatedValuesRef.costcode = null;

      const relevantPhases = getPhaseOptions({ ...selectOptionProps, projectId: inputValue });
      if (relevantPhases.length === 1 && relevantPhases[0].value === UNPHASED) {
        updatedValuesRef.phaseId = UNPHASED;
        updatedValuesRef.phase = UNPHASED;
      } else {
        updatedValuesRef.phaseId = null;
      }
    }
    if (property === 'phaseId') {
      updatedValuesRef.costcodeId = null;
      updatedValuesRef.costcode = null;
    }
    if (property === 'hourBased') {
      const startMillis = luxonDate.set({ hour: 0, minute: 0 }).toMillis();
      const endMillis = luxonDate.set({ hour: 12, minute: 0 }).toMillis();
      if (!startTime) {
        updatedValuesRef.startTime = startMillis;
      }
      if (!endTime) {
        updatedValuesRef.endTime = endMillis;
      }
    }

    if (fieldType === 'dropdown') {
      // Handle dynamic attribute linked value changes
      const relevantAttributeFields = getRelevantAttributeFields(property, customData);
      if (!relevantAttributeFields) return;

      const linkedIds = parsedValue.map(({ id: lId }) => lId);
      const lastIds = customData?.property?.values?.map(({ id: lId }) => lId) ?? [];

      relevantAttributeFields.forEach((fieldId) => {
        const field = updatedValuesRef.customData[fieldId];
        if (!field) return;

        const {
          value: attributeValue,
          dataType: attributeDataType,
          attribute,
          type: attributeType = '',
          useFormAuthorAttributes,
          linkField,
        } = field;

        const [data, dataDefaultValue] = getRelevantDataValues(attributeDataType, linkedIds);

        const linkedValues = getLinkedValues({
          dataType: attributeDataType,
          attribute,
          type: attributeType,
          useFormAuthorAttributes,
          linkField,
          responses: updatedValuesRef.customData,
          lastIds,
          currentValue: attributeValue,
          data,
          defaultValue: dataDefaultValue,
          idTransformer: attributeDataType === DATATYPES.Costcodes
            ? costCodeIdTransformer
            : noIdTransformer,
          authorId: selectedUser?.id,
          extraProps: {
            equipment,
            projectEquipment,
            equipmentTypeMap,
            divSet: divisionSet,
          },
        });

        if (!linkedValues) return;

        const { newValue: newAttributeValue } = linkedValues;

        updatedValuesRef.customData[fieldId] = {
          ...field,
          value: newAttributeValue,
        };
      });
    }
  };

  const handlePropertyValueChange = ({ updatedValues, parsedCustomData, parsedValue }) => {
    const updatedValuesRef = updatedValues;
    switch (fieldType) {
      case 'text':
      case 'attribute':
      case 'yes-no': {
        updatedValuesRef.customData = {
          ...parsedCustomData,
          [property]: {
            ...(parsedCustomData[property] || {}),
            value: parsedValue,
          },
        };
        break;
      }
      case 'dateTime': {
        updatedValuesRef.customData = {
          ...parsedCustomData,
          [property]: {
            ...(parsedCustomData[property] || {}),
            ...parsedValue,
          },
        };
        break;
      }
      case 'dropdown': {
        updatedValuesRef.customData = {
          ...parsedCustomData,
          [property]: {
            ...(parsedCustomData[property] || {}),
            values: parsedValue,
          },
        };
        break;
      }
      case 'time': {
        property.forEach((key) => {
          updatedValuesRef[key] = isNullOrUndefined(parsedValue[key]) && defaultValue?.[key]
            ? defaultValue[key] : parsedValue[key];
        });
        break;
      }
      default: {
        updatedValuesRef[property] = isNullOrUndefined(inputValue) && defaultValue
          ? defaultValue : parsedValue;
        break;
      }
    }
  };

  const onConfirm = async () => {
    if (rules && rules.length > 0) {
      const errors = rules.filter((rule) => !rule.validator(inputValue));
      if (errors.length > 0) {
        notification.error({
          message: 'Error',
          description: errors[0].message,
        });
        return;
      }
    }
    const { parsedValue, parsedCustomData } = handleInputParsing();

    const updatedValues = {};

    handlePropertyValueChange({ updatedValues, parsedValue, parsedCustomData });
    handlePropertyInteractions({ updatedValues, parsedValue });

    const payload = {
      breakEndTime,
      breakStartTime,
      canEdit: !readOnly,
      costcodeId,
      customData: parsedCustomData,
      date,
      divisionId,
      doubleOTEndTime,
      doubleOTStartTime,
      endTime,
      hourBased,
      note,
      otEndTime,
      otStartTime,
      phaseId,
      projectId,
      startTime,
      timezone,
      type,
      ...updatedValues,
    };

    onUpdate(id, payload);
    switchToDisplayMode();
  };

  const onChange = (e) => {
    if (inputType === 'text') {
      setInputValue(e.target.value);
    } else {
      setInputValue(e);
    }
    setInputOpen(false);
  };

  const InputComponent = useCallback((inputProps) => {
    switch (inputType) {
      case 'number': {
        return <OnTraccrNumberInput {...inputProps} />;
      }
      case 'dateTime': {
        return (
          <TimeEntryTableDateTimeInput
            {...inputProps}
            configProps={configProps}
            timezone={timezone}
          />
        );
      }
      case 'date': {
        return <DatePicker {...inputProps} allowClear={false} />;
      }
      case 'select': {
        if (fieldType === 'dropdown') {
          return (
            <TimeEntryTableDropdownInput
              {...inputProps}
              customDropdownProps={customDropdownProps}
              configProps={configProps}
              task={task}
              customData={customData}
              value={customData?.property?.values}
              responding={responding}
              responses={task.customData ?? {}}
            />
          );
        }

        const selectOptions = getSelectOptions({ ...task, ...selectOptionProps, settings });

        return (
          <Select
            {...inputProps}
            allowClear
            options={selectOptions}
          />
        );
      }
      case 'time': {
        return (
          <TimeEntryTableHoursInput
            {...inputProps}
            date={luxonDate}
            hourBased={!!hourBased}
            timezone={timezone}
          />
        );
      }
      default:
        return <OnTraccrTextInput {...inputProps} />;
    }
  }, [inputType, hourBased, selectOptionProps, task]);

  const onDisplayClick = () => {
    if (!isReadOnly) {
      setShowDisplay(false);
      setInputOpen(true);
    }
  };

  const onKeyDown = (e) => {
    if (!showDisplay) {
      e.stopPropagation();
    }

    // Close popover on escape
    if (e.key === 'Escape') {
      onCancel();
    }
  };

  const onBlur = (e) => {
    if (!inputOpen) return;
    const popupRef = getPopupRef(popconfirmRef);
    if (popupRef && popupRef.current && popupRef.current.contains(e.relatedTarget)) {
      return;
    }

    const notificationContainer = document.querySelector('.ant-notification');
    if (notificationContainer && notificationContainer.contains(e.relatedTarget)) {
      return;
    }

    onCancel();
  };

  const onFocus = () => {
    setInputOpen(true);
  };

  useEffect(() => {
    if (!showDisplay) {
      const isInputDirty = !areEqual(inputValue, initialValue);
      setShowPopover(isInputDirty);
    }
  }, [showDisplay, inputValue, initialValue, areEqual]);

  const displayValue = useMemo(() => {
    const display = displayFormatter({
      value: inputValue,
      task,
      property,
      timezone,
      projectIdMap,
      settings,
    });

    return (isNullOrUndefined(display) || display === '') ? <> &nbsp; </> : display;
  }, [
    fieldType,
    displayFormatter,
    inputValue,
    task,
    property,
    timezone,
    projectIdMap,
    settings,
  ]);

  const cellItem = useMemo(() => {
    if (!showDisplay) {
      return (
        <InputComponent
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onClick={onFocus}
          open={inputOpen}
          onPressEnter={onConfirm}
          autoFocus
          value={inputValue}
          style={{
            borderRadius: 6,
            borderColor: '#323232',
            width: '100%',
            ...inputStyle,
          }}
          suffixIcon={null}
        />
      );
    }

    return (
      <div
        onClick={onDisplayClick}
        style={{
          background: 'none',
          color: 'inherit',
          border: 'none',
          padding: 0,
          font: 'inherit',
          cursor: readOnly ? 'auto' : 'pointer',
          outline: 'inherit',
          display: 'table-cell',
          verticalAlign: 'middle',
          textAlign: 'left',
          maxWidth: inputWidth || 250,
          minWidth: inputWidth || 250,
          textWrap: 'wrap',
        }}
      >
        {displayValue}
      </div>
    );
  }, [inputOpen, inputValue, onChange, onBlur, onFocus, onConfirm, displayValue, inputWidth]);

  const containerStyle = showDisplay
    ? {}
    : {
      position: 'absolute',
      transform: 'translate(-10px, -15px)',
      maxWidth: inputWidth || 250,
      minWidth: inputWidth || 250,
      marginLeft: inputWidth ? 10 : 0,
      zIndex: 1,
    };

  return (
    <div
      ref={containerRef}
      onKeyDown={onKeyDown}
      style={containerStyle}
      role="button"
      tabIndex={0}
    >
      <Popconfirm
        visible={showPopover}
        title={title}
        okText="Update"
        onConfirm={onConfirm}
        onCancel={onCancel}
        ref={popconfirmRef}
        placement="left"
      >
        {cellItem}
      </Popconfirm>
    </div>
  );
}

const MemoizedTimeEntryTableInputComponent = React.memo(TimeEntryTableInput);

export default MemoizedTimeEntryTableInputComponent;

TimeEntryTableInput.propTypes = {
  fieldType: PropTypes.string,
  inputType: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.any,
  ]),
  displayFormatter: PropTypes.func,
  inputStyle: PropTypes.shape({}),
  task: PropTypes.shape({
    id: PropTypes.oneOf([PropTypes.string], [PropTypes.number]),
    costcodeId: PropTypes.string,
    phaseId: PropTypes.string,
    projectId: PropTypes.string,
    divisionId: PropTypes.string,
    date: PropTypes.string,
    note: PropTypes.string,
    type: PropTypes.string,
    startTime: PropTypes.number,
    endTime: PropTypes.number,
    hourBased: PropTypes.bool,
    customData: PropTypes.oneOf(
      [PropTypes.shape({})],
      [PropTypes.arrayOf(PropTypes.shape({}))],
    ),
  }).isRequired,
  getSelectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  selectOptionProps: PropTypes.shape({
    activeCostcodes: PropTypes.arrayOf(PropTypes.shape({})),
    activeProjects: PropTypes.arrayOf(PropTypes.shape({})),
    activePhases: PropTypes.arrayOf(PropTypes.shape({})),
    user: PropTypes.shape({}),
    users: PropTypes.arrayOf(PropTypes.shape({})),
    teams: PropTypes.arrayOf(PropTypes.shape({})),
    userDivisions: PropTypes.arrayOf(PropTypes.shape({})),
    divisionId: PropTypes.string,
    projectId: PropTypes.string,
    phaseId: PropTypes.string,
  }),
  areEqual: PropTypes.func,
  property: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.any,
  ]),
  rules: PropTypes.arrayOf(PropTypes.shape({
    message: PropTypes.string,
    validator: PropTypes.func,
  })),
  width: PropTypes.number,
  readOnly: PropTypes.bool,
  customDropdownProps: PropTypes.shape({}),
  timezone: PropTypes.string,
  configProps: PropTypes.shape({}),
  responding: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
};

TimeEntryTableInput.defaultProps = {
  fieldType: 'text',
  inputType: 'text',
  title: '',
  value: '',
  displayFormatter: null,
  inputStyle: {},
  areEqual: simpleEquality,
  defaultValue: null,
  timezone: DateTime.local().zoneName,
  rules: [],
  width: null,
  readOnly: false,
  property: null,
  getSelectOptions: null,
  selectOptionProps: {},
  customDropdownProps: {},
  configProps: {},
  responding: false,
};
