/* eslint-disable class-methods-use-this */
import * as XLSX from 'xlsx';
import { notification } from 'antd';
import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';
import moment from 'moment';

import Analytics from '../helpers/Analytics';
import PDFTableExport from '../common/pdf/PDFExport/PDFTableExport';

import { getDataKey } from './Exports/exports.helpers';
import { fixedDecimalKeys } from './Exports/exports.constants';

const toFixed = (n) => (typeof n === 'number' ? n.toFixed(2) : null);

const ROW_PARSERS = {
  notes: (item = {}) => item.rawNotes ?? '',
};

const parseValue = ({ key, item = {} }) => {
  if (!(key in ROW_PARSERS)) return item[key] ?? '';
  return ROW_PARSERS[key](item);
};

// This set holds the keys that we ignore when calculating totals.
const TOTAL_EXCLUDE_SET = new Set([
  'name',
  'date',
  'division',
  'wage',
  'notes',
  'project',
  'phase',
  'costcode',
  'serviceLocation',
]);

const FIXED_EXPORT_SET = new Set(fixedDecimalKeys);
const TOP_LEVEL_COLUMNS = [['Employee', 'Employee ID', 'Regular Hours', 'OT Hours', 'Double OT Hours', 'Total Pay']];

const LIGHT_GRID_LINES = {
  hLineWidth: (i) => (i === 1 ? 1 : 0.5),
  vLineWidth: () => 0.5,
  hLineColor: (i) => (i === 1 ? 'black' : 'lightgray'),
  vLineColor: () => 'lightgray',
};

export default class ReportExcelExport {
  constructor(title, settings, includeTopLevel, dateRange = []) {
    this.columns = [];
    this.data = [];
    this.filters = {};
    this.title = title;
    this.settings = settings || {};
    this.includeTopLevel = includeTopLevel || false;
    this.dateRange = dateRange;
    this.isRounded = false;
    this.topLevelColumns = TOP_LEVEL_COLUMNS;
    this.topLevelData = null;
  }

  resetTopLevel() {
    this.topLevelColumns = TOP_LEVEL_COLUMNS;
    this.topLevelData = null;
  }

  updateSettings(settings = {}) {
    this.settings = settings;
  }

  updateData(data = []) {
    this.data = data;
  }

  updateColumns(columns = []) {
    this.columns = columns;
  }

  updateFilters(filters = {}) {
    this.filters = filters;
  }

  updateDateRange(newRange) {
    this.dateRange = newRange;
  }

  onRoundedChanged(rounded) {
    this.isRounded = rounded;
  }

  setTitle(report) {
    this.title = report;
  }

  setTopLevelColumns(columns) {
    this.topLevelColumns = columns;
  }

  setTopLevelData(data) {
    this.topLevelData = data;
  }

  topLevelPayroll() {
    const users = {};
    this.data.forEach((task) => {
      if (users[task.name]) {
        users[task.name].regularHours += task.regHoursRaw;
        users[task.name].otHours += task.regularOTRaw;
        users[task.name].doubleOT += task.doubleOTRaw;
      } else {
        users[task.name] = {
          name: task.name,
          employeeId: task.employeeId || '',
          wage: task.wage,
          tasks: [task],
          breakHours: task.breakHoursRaw,
          regularHours: task.regHoursRaw,
          otHours: task.regularOTRaw,
          doubleOT: task.doubleOTRaw,
        };
      }
    });
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    const sumRow = new Array(6).fill(0);
    sumRow[0] = '';

    const rows = Object.values(users).map((user) => {
      const {
        name,
        employeeId,
        wage: rawWage = '$0',
        regularHours,
        otHours,
        doubleOT,
      } = user;
      const wage = parseFloat(rawWage.substr(1));

      const rawTotalPay = regularHours * wage + otHours * wage * 1.5 + doubleOT * wage * 2;
      const totalPay = formatter.format(rawTotalPay);
      if (regularHours && !Number.isNaN(regularHours)) sumRow[1] += regularHours;
      if (otHours && !Number.isNaN(otHours))sumRow[2] += otHours;
      if (doubleOT && !Number.isNaN(doubleOT)) sumRow[3] += doubleOT;
      if (rawTotalPay && !Number.isNaN(rawTotalPay)) sumRow[4] += rawTotalPay;
      return [
        name,
        employeeId,
        toFixed(regularHours),
        toFixed(otHours),
        toFixed(doubleOT),
        totalPay.includes('$NaN') ? '' : totalPay,
      ];
    });

    sumRow[1] = toFixed(sumRow[1]);
    sumRow[2] = toFixed(sumRow[2]);
    sumRow[3] = toFixed(sumRow[3]);
    sumRow[4] = formatter.format(sumRow[4]);

    rows.push(sumRow);

    return rows;
  }

  getHeaders() {
    const headers = [[]]; // Body Table column name
    const headerKeyMap = []; // Body Table column key
    this.columns.forEach((col) => {
      // Column could be a string instead of an object
      if (typeof col === 'string') {
        headers[0].push(col);
        headerKeyMap.push(col);
        return;
      }

      headers[0].push(col.titleString);
      headerKeyMap.push(col.key);
    });
    return { headers, headerKeyMap };
  }

  constructByProjectData({
    headers = [[]],
    headerKeyMap = [],
  }) {
    const dataByProject = {};
    this.data.forEach((item) => {
      const { project } = item;
      if (!(project in dataByProject)) dataByProject[project] = [];
      dataByProject[project].push(item);
    });
    const sortedKeys = Object.keys(dataByProject);
    sortedKeys.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));

    let topLevel = this.topLevelData;

    if (!this.topLevelData) {
      topLevel = this.includeTopLevel ? this.topLevelPayroll() : [];
    }
    const dataByProjectArray = sortedKeys.map((projectName) => ({
      ...this.constructBodyData({ data: dataByProject[projectName], headerKeyMap }),
      projectName,
    }));
    return {
      topLevelHeader: this.topLevelColumns,
      topLevelData: topLevel,
      dataByProject: dataByProjectArray,
      bodyHeader: headers,
    };
  }

  constructBodyData({
    data = this.data,
    headerKeyMap = [],
  }) {
    const totalRow = new Array(headerKeyMap.length).fill(0);
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    let totalCostIndex = -1;
    const body = data.map((item) => (
      headerKeyMap.map((key, keyIdx) => {
        if (Array.isArray(item)) return item[keyIdx];
        if (key === 'totalCost') totalCostIndex = keyIdx;
        const value = parseValue({ key, item });
        if (!TOTAL_EXCLUDE_SET.has(key)) {
          const strippedVal = value && typeof value === 'string' ? value.replace(/\$/g, '').replace(/,/g, '') : value;
          const floatVal = parseFloat(strippedVal);
          if (floatVal && !Number.isNaN(floatVal)) {
            totalRow[keyIdx] += floatVal;
          }
        } else {
          totalRow[keyIdx] = '';
        }
        return value;
      })
    ));

    const summaryRow = totalRow.map((value, idx) => {
      if (typeof value === 'string') return value;
      return idx === totalCostIndex ? formatter.format(value) : toFixed(value);
    });
    return {
      bodyData: body,
      summaryRow,
    };
  }

  constructExportData({
    data = this.data,
    headers = [[]],
    headerKeyMap = [],
  }) {
    let topLevel = this.topLevelData;

    if (!this.topLevelData) {
      topLevel = this.includeTopLevel ? this.topLevelPayroll() : [];
    }
    return {
      topLevelHeader: this.topLevelColumns,
      topLevelData: topLevel,
      bodyHeader: headers,
      ...this.constructBodyData({ data, headerKeyMap }),
    };
  }

  /**
   * Constructs PDF export
   * @param {object} companyImageURL
   * @param {array} topLevelHeader
   * @param {array} topLevelData
   * @param {array} bodyHeader
   * @param {array} bodyData
   * @param {array} summaryRow
   */
  async exportPDF({
    companyImageURL,
    topLevelHeader,
    topLevelData,
    bodyHeader,
    bodyData,
    summaryRow,
    dataByProject,
    isByProject,
  }) {
    const pdfExportConstructor = new PDFTableExport({
      styles: {
        tableHeader: {
          alignment: 'center',
          fillColor: '#eeeeee',
          fontSize: 8,
          bold: true,
        },
        table: {
          margin: [0, 10, 0, 0],
          alignment: 'center',
          fontSize: 7,
          bold: true,
        },
      },
    });

    // Prepare Header:
    const logoEntry = await PDFTableExport.getCompanyLogo(companyImageURL);
    const leftColumn = PDFTableExport.createColumnList({
      content: [
        { text: this.title, style: 'header' },
      ],
    });

    const [start, end] = this.dateRange;
    const startMillis = moment(start).valueOf();
    const endMillis = moment(end).valueOf();
    const timeRangeLabel = PDFTableExport.getTimeRangeLabel(startMillis, endMillis);
    const rightColumn = PDFTableExport.createColumnList({
      content: [
        { text: timeRangeLabel, style: 'header' },
      ],
    });

    const headerColumns = [leftColumn];

    if (!this.settings?.hideDate) {
      headerColumns.push(rightColumn);
    }

    const header = PDFTableExport.createHeader({
      useLogo: true,
      logoEntry,
      columns: headerColumns,
    });

    // Prepare Top Level Table:
    const adjustedTopLevelHeader = topLevelHeader[0].map((text) => ({ text, style: 'tableHeader' }));
    const topLevelTable = PDFTableExport.createTable({
      widths: Array(adjustedTopLevelHeader.length).fill('auto'),
      header: adjustedTopLevelHeader,
      // Deep copy is required otherwise the original data is modified
      rows: topLevelData ? JSON.parse(JSON.stringify(topLevelData)) : topLevelData,
      layout: LIGHT_GRID_LINES,
    });

    const body = [
      topLevelTable,
    ];

    const adjustedMainTableHeader = bodyHeader[0].map((text) => ({ text, style: 'tableHeader' }));

    if (isByProject) {
      dataByProject.forEach(({
        projectName,
        bodyData: projectData,
        summaryRow: projectSummary,
      }) => {
        const realProjectName = !projectName || projectName === 'null'
          ? 'None'
          : projectName;
        body.push(
          PDFTableExport.createTableTitle(realProjectName),
        );
        body.push(
          PDFTableExport.createTable({
            widths: Array(adjustedMainTableHeader.length).fill('auto'),
            /*
              Have to recreate header here or it doesnt show correctly
              on tables after the first

              See: https://stackoverflow.com/questions/76190832/pdfmake-table-headers-not-coming-appearing-for-a-few-tables
            */
            header: bodyHeader[0].map((text) => ({ text, style: 'tableHeader' })),
            rows: [...projectData, projectSummary],
            layout: LIGHT_GRID_LINES,
          }),
        );
      });
    } else {
    // Prepare Main Table:

      const mainTable = PDFTableExport.createTable({
        widths: Array(adjustedMainTableHeader.length).fill('auto'),
        header: adjustedMainTableHeader,
        rows: [...bodyData, summaryRow],
        layout: LIGHT_GRID_LINES,
      });
      if (!this.settings?.hideBodyRow) {
        body.push(mainTable);
      }
    }

    // Export PDF:
    const pageOrientation = adjustedMainTableHeader.length > 10
      || (bodyHeader[0].includes('Notes') && adjustedMainTableHeader.length > 8)
      ? 'landscape' : 'portrait';

    pdfExportConstructor.export({
      name: this.title,
      header,
      pageOrientation,
      body,
    });
  }

  /**
   * Constructs Excel export
   * @param {array} topLevelHeader
   * @param {array} topLevelData
   * @param {array} bodyHeader
   * @param {array} bodyData
   * @param {array} summaryRow
   */
  exportExcel({
    topLevelHeader, topLevelData, bodyHeader, bodyData, summaryRow,
  }) {
    const workbook = XLSX.utils.book_new();
    const body = !this.settings?.hideBodyRow
      ? bodyHeader.concat(bodyData)
      : [];

    const rows = (
      this.includeTopLevel
        ? topLevelHeader.concat(topLevelData.concat([[]].concat(body)))
        : body
    );
    if (!this.settings?.hideSummaryRow) rows.push(summaryRow);
    const sheet = XLSX.utils.aoa_to_sheet(rows);
    XLSX.utils.book_append_sheet(workbook, sheet, this.title.slice(0, 31));
    XLSX.writeFile(workbook, `${this.title}.xlsx`);
  }

  /**
   * Report Export handler
   * @param {boolean} isPDF
   * @param {object | undefined} companyImageURL
   */
  export(type, companyImageURL) {
    try {
      Analytics.track('Reports/Export', { ExportFileType: type });
      const isByProject = type === 'byProject';
      const { headers, headerKeyMap } = this.getHeaders();
      const exportData = isByProject
        ? this.constructByProjectData({
          headers,
          headerKeyMap,
        })
        : this.constructExportData({
          headers,
          headerKeyMap,
        });
      if (type === 'pdf' || isByProject) {
        this.exportPDF({
          ...exportData,
          companyImageURL,
          type,
          isByProject,
        });
      } else {
        this.exportExcel(exportData);
      }
    } catch (err) {
      let message = 'Details:';
      if (err.name) message += ` ${err.name}`;
      if (err.message) message += ` ${err.message}`;
      if (message.length === 8) message += ' Unknown';
      notification.error({
        message: 'Failed to Export Report',
        description: message,
      });
    }
  }

  getCurrentState() {
    return {
      columns: this.columns.map((col) => col.key),
      filters: this.filters,
    };
  }

  createSheet(workbook, title, { columns = [], data = [], addLineNumbers }) {
    if (data.length === 0) {
      notification.warn({ message: 'No data to export' });
      return workbook;
    }

    try {
      let fullColumns = [];
      if (addLineNumbers) {
        fullColumns.push({ title: '' });
      }
      fullColumns = fullColumns.concat(columns);
      const matrix = [[]];
      fullColumns.forEach((col) => {
        matrix[0].push(col.title);
      });
      data.forEach((row, rowIndex) => {
        matrix.push(
          fullColumns.map((col, index) => {
            if (addLineNumbers && index === 0) {
              return rowIndex + 1;
            }
            const { field } = col;
            const {
              [getDataKey(col)]: datum = '',
            } = row;
            if (FIXED_EXPORT_SET.has(field)) {
              const floatVal = parseFloat(datum);
              if (!Number.isNaN(floatVal)) return floatVal;
            }
            return datum;
          }),
        );
      });
      // Sheet name cannot exceed 31 char or include certain special characters
      const validTitle = title.replace(/[^a-zA-Z0-9 ]/g, '').slice(0, 31);
      const sheet = XLSX.utils.aoa_to_sheet(matrix);
      XLSX.utils.book_append_sheet(workbook, sheet, validTitle.slice(0, 31));
    } catch (err) {
      Sentry.withScope(() => {
        Sentry.captureException(err);
      });
      notification.error({
        message: `Failed to export: ${title}`,
      });
    }

    return workbook;
  }

  createFormListExport(groupedForms = []) {
    if (groupedForms.length === 0) {
      notification.warn({ message: 'No forms to export' });
      return;
    }

    try {
      let workbook = XLSX.utils.book_new();

      groupedForms.forEach((form) => {
        const {
          columns = [],
          data = [],
          templateName,
        } = form;

        workbook = this.createSheet(workbook, templateName, { columns, data });
      });

      const fileName = `ExportedForms-${DateTime.local().toLocaleString(DateTime.DATETIME_MED)}.xlsx`;
      XLSX.writeFile(workbook, fileName);
    } catch (err) {
      Sentry.withScope(() => {
        Sentry.captureException(err);
      });
      notification.error({
        message: 'Failed to export',
      });
    }
  }

  createCustomExport(title, {
    columns = [], data = [], addLineNumbers, batchNumber,
  }) {
    if (data.length === 0) {
      notification.warn({ message: 'No data to export' });
      return false;
    }
    try {
      const workbook = XLSX.utils.book_new();
      const sheetSuffix = batchNumber ? `- ${batchNumber}` : '';
      const newWorkbook = this.createSheet(workbook, `${title}${sheetSuffix}`, { columns, data, addLineNumbers });

      const suffix = batchNumber ?? DateTime.local().toLocaleString(DateTime.DATETIME_MED);

      const fileName = `${title}-${suffix}.xlsx`;
      XLSX.writeFile(newWorkbook, fileName);
      return true;
    } catch (err) {
      Sentry.withScope(() => {
        Sentry.captureException(err);
      });
      notification.error({
        message: 'Failed to export',
      });
      return false;
    }
  }
}
