import React from 'react';
import moment from 'moment-timezone';
import axios from 'axios';
import { PDFDocument } from 'pdf-lib';
import { DateTime, Duration } from 'luxon';
import { TaskHelpers } from 'ontraccr-common';
import * as Sentry from '@sentry/react';
import { message } from 'antd';

import DateTimeWithZone from '../../common/text/DateTimeWithZone';

import { constructFormPayloadForAPI } from '../../forms/ResponderHelpers';
import {
  getIdMap, isNullOrUndefined, sortByCode, uuid, toTitleCase,
} from '../../helpers/helpers';
import { constructCompletedFormPDF, decorateFormWithFiles } from '../../forms/formHelpers';
import Permissions from '../../auth/Permissions';
import { formatProjectLabelFromCompanySettings } from '../../projects/projectHelpers';

const axiosFileInstance = axios.create();

export default {};

export const UNPHASED = 'Unphased';

export const unphasedCode = { id: UNPHASED, name: UNPHASED };

const formatTaskBase = (task = {}) => {
  const {
    date,
    startTime,
    endTime,
    costcodeId,
    costcode,
    projectId,
    project,
    phase,
    phaseId,
    type,
    note,
    classId,
    sageShiftId,
    hourBased,
    userId,
    breakStartTime,
    breakEndTime,
    otStartTime,
    otEndTime,
    doubleOTStartTime,
    doubleOTEndTime,
    excludeFromAutoBreak,
  } = task;

  const {
    id: costcodeObjectId = costcodeId,
  } = costcode || {};

  const {
    id: projectObjectId = projectId,
  } = project || {};

  const {
    id: phaseObjectId = phaseId,
  } = phase || {};
  // If note is an empty string, backend will reject
  // eslint thinks we should use default assignment
  // which doesnt work for an empty string
  return {
    type,
    note: note ? note : undefined, // eslint-disable-line
    phaseId: phaseObjectId === UNPHASED || !phaseObjectId ? null : phaseObjectId,
    costcodeId: costcodeObjectId ?? null,
    projectId: projectObjectId ?? null,
    classId: classId ? classId : undefined, // eslint-disable-line
    sageShiftId: sageShiftId ? sageShiftId : undefined, // eslint-disable-line
    startTime,
    endTime,
    hourBased: !!hourBased,
    date,
    userId,
    breakStartTime,
    breakEndTime,
    otStartTime,
    otEndTime,
    doubleOTStartTime,
    doubleOTEndTime,
    excludeFromAutoBreak: !!excludeFromAutoBreak,
  };
};

const prepareTasksForUpload = async ({ tasks = [], addId }) => {
  const newTasks = [];
  let fullFiles = [];
  await Promise.all(
    tasks.map(async (task = {}) => {
      const {
        id,
        customData,
        divisionId,
        timezone,
      } = task;

      const missingClockInOrOut = !TaskHelpers.getStartTime(task) || !TaskHelpers.getEndTime(task);
      if (missingClockInOrOut) return;

      // If note is an empty string, backend will reject
      // eslint thinks we should use default assignment
      // which doesnt work for an empty string
      let newTask = formatTaskBase(task);

      if (timezone) newTask.timezone = timezone; // Copy will have timezone
      if (addId) newTask.id = id;
      else {
        newTask = TaskHelpers.decorateTaskWithDate({
          dateTimestamp: TaskHelpers.getEndTime(task),
          payload: newTask,
        });
      }

      if (customData && !Array.isArray(customData)) {
        const {
          data: {
            sections,
          } = {},
          files,
        } = await constructFormPayloadForAPI({
          form: {
            collected: {}, responses: customData,
          },
        });
        newTask.customData = sections;
        newTask.divisionId = divisionId;
        fullFiles = fullFiles.concat(files);
      }

      newTasks.push(newTask);
    }),
  );

  return { tasks: newTasks, files: fullFiles };
};

export const prepareManualEntryPayload = async (rawTask = {}) => {
  const {
    tasksToAdd: entriesToAdd = [],
    tasksToEdit: entriesToEdit = [],
    tasksToRemove: entriesToRemove = [],
  } = rawTask;

  const {
    tasks: tasksToAdd,
    files: addFiles = [],
  } = await prepareTasksForUpload({
    tasks: entriesToAdd,
  });

  const {
    tasks: tasksToEdit,
    files: editFiles = [],
  } = await prepareTasksForUpload({
    tasks: entriesToEdit,
    addId: true,
  });

  const tasksToRemove = entriesToRemove?.map((task) => ({
    id: task.id,
  })) ?? [];

  return {
    tasksToAdd,
    tasksToEdit,
    tasksToRemove,
    files: addFiles.concat(editFiles),
  };
};

export const zoneToLocalTS = (timestamp, timezone) => {
  if (!timezone || timezone === DateTime.local().zoneName) {
    return timestamp;
  }
  const zoneDT = DateTime.fromMillis(timestamp, { zone: timezone });
  const localDt = DateTime.fromObject(zoneDT.toObject());
  return localDt.toMillis();
};

export const localToZoneTS = (timestamp, timezone) => {
  if (!timezone || timezone === DateTime.local().zoneName) {
    return timestamp;
  }
  const localDT = DateTime.fromMillis(timestamp);
  const zoneDT = DateTime.fromObject({ ...localDT.toObject(), zone: timezone });
  return zoneDT.toMillis();
};

export const dateValidator = (form, hourBased) => async (_, value) => {
  if (!value || typeof value !== 'string') return Promise.reject(new Error('Date is required'));
  if (moment(value).isAfter(moment().endOf('day'))) return Promise.reject(new Error('Can\'t create a task in the future'));
  if (!hourBased) {
    // Need to revalidate times to see if they are in the future
    try {
      const {
        startTime,
        endTime,
      } = form.getFieldValues();
      if (!isNullOrUndefined(startTime) && !isNullOrUndefined(endTime)) {
        await form.validateFields(['startTime', 'endTime']);
      }
    } catch (err) {
      // Handled by antd form
    }
  }
  return Promise.resolve();
};

// Shifts an entry by given offset to maintain ordering
const getShiftedHourBasedEntry = (entry, offset) => {
  const newEntry = { ...entry };
  const {
    startTime,
    endTime,
    otStartTime,
    otEndTime,
    doubleOTStartTime,
    doubleOTEndTime,
    breakStartTime,
    breakEndTime,
  } = newEntry;

  if (startTime && endTime) {
    const duration = entry.endTime - entry.startTime;
    newEntry.startTime = startTime + offset;
    newEntry.endTime = startTime + duration + offset;
  }

  if (otStartTime && otEndTime) {
    const duration = otEndTime - otStartTime;
    newEntry.otStartTime = otStartTime + offset;
    newEntry.otEndTime = otStartTime + duration + offset;
  }

  if (doubleOTStartTime && doubleOTEndTime) {
    const duration = doubleOTEndTime - doubleOTStartTime;
    newEntry.doubleOTStartTime = doubleOTStartTime + offset;
    newEntry.doubleOTEndTime = doubleOTStartTime + duration + offset;
  }

  if (breakStartTime && breakEndTime) {
    const duration = breakEndTime - breakStartTime;
    newEntry.breakStartTime = breakStartTime + offset;
    newEntry.breakEndTime = breakStartTime + duration + offset;
  }

  return newEntry;
};

export const addOrUpdateEntries = ({
  entries = [], // Entries in the days tasks
  newEntry = {}, // Entry being added/modified
  selectedEntry, // The task selected for editing. Does not exist when adding a new task.
  userId,
}) => {
  let formattedEntries;
  let newId;
  if (!selectedEntry || selectedEntry?.userId !== userId) {
    newId = uuid();
    const fullEntry = {
      ...newEntry, id: newId,
    };
    let offset = -1;
    formattedEntries = entries.concat([fullEntry]).map((entry) => {
      if (!fullEntry.hourBased) return { ...entry, userId };
      const taskDate = TaskHelpers.getTaskDate(entry)?.toSQLDate();
      if (entry.hourBased
        && taskDate === fullEntry.date) {
        offset += 1;
      }
      if (entry.id === fullEntry.id) {
        // reorder
        return {
          ...getShiftedHourBasedEntry(entry, offset),
          isUpdated: true,
          userId,
        };
      }
      return entry;
    });
  } else {
    formattedEntries = entries.map((entry) => {
      if (entry.id !== selectedEntry.id) return entry;
      return {
        ...selectedEntry,
        ...newEntry,
        isUpdated: true,
        userId,
      };
    });
  }

  formattedEntries.sort((a, b) => a.startTimeStamp - b.startTimeStamp);
  return { formattedEntries, newId };
};

export const getTimeText = ({
  hourBased,
  type,
  startTime,
  endTime,
  timezone,
}, {
  showAbbreviatedDuration = true,
  returnComponent = true,
  addTypeAsSuffix = false,
} = {}) => {
  if (
    startTime === endTime
    || (isNullOrUndefined(startTime) && isNullOrUndefined(endTime))
  ) return null;

  const prefix = !addTypeAsSuffix && type ? `${type}: ` : '';
  const suffix = addTypeAsSuffix && type ? ` (${type})` : '';
  if (!hourBased) {
    if (isNullOrUndefined(endTime) && isNullOrUndefined(startTime)) return null;

    if (!returnComponent) {
      // Only display timezone if there is no end time
      const startFormat = !isNullOrUndefined(endTime) ? 'hh:mm a' : 'hh:mm a ZZZZ';
      const startStr = !isNullOrUndefined(startTime)
        ? DateTime.fromMillis(startTime, { zone: timezone }).toFormat(startFormat)
        : '';
      const endStr = !isNullOrUndefined(endTime)
        ? DateTime.fromMillis(endTime, { zone: timezone }).toFormat('hh:mm a ZZZZ')
        : '';
      const connector = startStr && endStr ? ' -> ' : '';

      return `${prefix}${startStr}${connector}${endStr}${suffix}`;
    }
    return (
      <DateTimeWithZone
        type={type}
        startTime={startTime}
        endTime={endTime}
        timezone={timezone}
        style={{ marginBottom: 0 }}
        addTypeAsSuffix={addTypeAsSuffix}
      />
    );
  }

  if (isNullOrUndefined(endTime) || isNullOrUndefined(startTime)) return null;

  const duration = Duration.fromMillis(endTime - startTime).shiftTo('hours', 'minutes');
  let text = prefix;
  const {
    hours,
    minutes,
  } = duration.toObject();
  const hInt = parseInt(hours, 10);
  const mInt = parseInt(minutes, 10);
  if (hInt > 0) text += `${hInt}${showAbbreviatedDuration ? 'h' : ' hour(s)'}`;
  if (mInt > 0) {
    if (text.length > 0) text += ' ';
    text += `${mInt}${showAbbreviatedDuration ? 'm' : ' minute(s)'}`;
  }
  text += suffix;

  if (returnComponent) {
    return (
      <>
        <span className="bold-text">{prefix}</span>
        {text}
      </>
    );
  }

  return text;
};

const getTypeText = (type) => {
  switch (type) {
    case 'break': return 'Break';
    case 'overtime': return 'Overtime';
    default: return 'Regular';
  }
};

export const getAllTimesText = ({
  hourBased,
  startTime,
  endTime,
  timezone,
  breakStartTime,
  breakEndTime,
  otStartTime,
  otEndTime,
  doubleOTStartTime,
  doubleOTEndTime,
  type,
}, {
  showAbbreviatedDuration = true,
  returnComponent = true,
  joinText = '\n',
  showRegularTypeText = true,
  addTypeAsSuffix = false,
} = {}) => {
  const results = [];

  const timeText = getTimeText({
    type: showRegularTypeText ? getTypeText(type) : null,
    hourBased,
    startTime: startTime || null,
    endTime: endTime || null,
    timezone,
  }, { showAbbreviatedDuration, returnComponent, addTypeAsSuffix });

  if (timeText) results.push(timeText);

  const breakText = getTimeText({
    type: 'Break',
    hourBased,
    startTime: breakStartTime || null,
    endTime: breakEndTime || null,
    timezone,
  }, { showAbbreviatedDuration, returnComponent, addTypeAsSuffix });

  if (breakText) results.push(breakText);

  const otText = getTimeText({
    type: 'Overtime',
    hourBased,
    startTime: otStartTime || null,
    endTime: otEndTime || null,
    timezone,
  }, { showAbbreviatedDuration, returnComponent, addTypeAsSuffix });

  if (otText) results.push(otText);

  const doubleOTText = getTimeText({
    type: 'Double Overtime',
    hourBased,
    startTime: doubleOTStartTime || null,
    endTime: doubleOTEndTime || null,
    timezone,
  }, { showAbbreviatedDuration, returnComponent, addTypeAsSuffix });

  if (doubleOTText) results.push(doubleOTText);

  if (!results.length) {
    return '';
  }

  if (returnComponent) {
    return (
      <>
        {results.map((result, index) => (
          <>
            {result}
            {(index < results.length - 1) && <br />}
          </>
        ))}
      </>
    );
  }

  return results.join(joinText);
};

export const TextRow = function TextRow({ title, text }) {
  return (
    <span>
      <b>
        {title}
        :
      </b>
      {' '}
      {text}
    </span>
  );
};

export const getName = (id, idMap = {}) => {
  const { [id]: { name } = {} } = idMap;
  return name;
};

export const getCostcodeName = ({
  costcodeId,
  costcodeIdMap,
}) => {
  if (!(costcodeId in costcodeIdMap)) return null;
  const { [costcodeId]: { name, code } = {} } = costcodeIdMap;
  return `${code} - ${name}`;
};

export const getProjectRowNames = ({
  projectId,
  phaseId,
  costcodeId,
  projects,
  phases,
  costcodes,
}) => {
  const projectIdMap = getIdMap(projects);
  const phaseIdMap = getIdMap(phases);
  const costcodeIdMap = getIdMap(costcodes);
  const project = projectIdMap[projectId];
  const ourName = getName(phaseId, phaseIdMap);
  const phaseName = !ourName && projectId && costcodeId ? 'Unphased' : ourName;
  const costcodeName = getCostcodeName({
    costcodeId,
    costcodeIdMap,
  });
  return {
    project,
    projectName: `${project?.number} - ${project?.name}`,
    phaseName,
    costcodeName,
  };
};

export const loadManualEntryData = async (taskIds, includeFiles) => {
  const {
    data: {
      dataMap = {},
      fileMap = {},
    } = {},
  } = await axios.get('/tasks/data', { params: { taskIds } });

  if (includeFiles) {
    await decorateFormWithFiles({ fileMap });
  }

  return {
    dataMap,
    fileMap,
  };
};

export const loadTasks = async ({
  startTime,
  endTime,
  userId,
  projectId,
}) => {
  try {
    const payload = { startTime, endTime };
    if (userId) payload.userId = userId;
    if (projectId) payload.projectId = projectId;
    const { data: tasks = [] } = await axios.post('/tasks/get', payload);
    return tasks;
  } catch (err) {
    Sentry.captureException(err);
    const msg = 'Tasks could not be retrieved for the date(s). Conflicts, Auto-break, and Auto-rounding may be affected';
    message.error(msg);
    return [];
  }
};

const constructTimecardPDFHeader = (entry, t, timeText) => {
  /*
    Time based entries have a timezone suffix,
    which causes the line to wrap so
    we add another line to the title
    so the subsequent rows line up
  */
  let timeSuffix = entry.hourBased ? '' : '\n';
  // Need to keep the same number of lines
  const timeLines = timeText.split('\n');
  if (timeLines.length > 1) {
    timeLines.forEach(() => {
      timeSuffix += '\n';
    });
  }
  const additionalCollectedHeaderItems = [{
    title: `Time${timeSuffix}`,
    response: timeText,
    link: {
      collectedId: 'time',
    },
  }];

  if (entry.projectId) {
    additionalCollectedHeaderItems.push({
      title: t('Project'),
      response: entry.projectName,
      link: {
        collectedId: 'project',
      },
    });
  }

  if (entry.phase) {
    additionalCollectedHeaderItems.push({
      title: 'Phase',
      response: entry.phase,
      link: {
        collectedId: 'phase',
      },
    });
  }

  if (entry.fullCostcode) {
    additionalCollectedHeaderItems.push({
      title: 'Cost Code',
      response: entry.fullCostcode,
      link: {
        collectedId: 'costcode',
      },
    });
  }

  if (entry.className) {
    // Needs newline or Classification gets split with hyphen
    additionalCollectedHeaderItems.push({
      title: 'Work\nClassification',
      response: entry.className,
      link: {
        collectedId: 'className',
      },
    });
  }

  if (entry.startLatitude && entry.startLongitude) {
    additionalCollectedHeaderItems.push({
      title: 'Start Location',
      response: `${entry.startLatitude}, ${entry.startLongitude}`,
      link: {
        collectedId: 'startLocation',
      },
    });
  }

  if (entry.endLatitude && entry.endLongitude) {
    additionalCollectedHeaderItems.push({
      title: 'End Location',
      response: `${entry.endLatitude}, ${entry.endLongitude}`,
      link: {
        collectedId: 'endLocation',
      },
    });
  }

  return additionalCollectedHeaderItems;
};

export const constructTimecardPDF = async (processedEntries, data, t) => {
  const {
    employeeId,
    name,
    settings,
    logo,
    fileMap,
    projectIdMap,
  } = data;
  let signatureData;

  const signedTask = processedEntries.find((entry) => entry.signatureReferenceId);
  if (signedTask) {
    try {
      const { data: signedURL } = await axios.get(`/signatures/${signedTask.userId}/${signedTask.signatureReferenceId}`);
      const {
        data: signature,
      } = await axiosFileInstance.get(signedURL, {
        responseType: 'arraybuffer',
      });
      signatureData = new File([signature], 'signature.png', { type: 'image' });
    } catch {
      // No op
    }
  }

  const pdfs = await Promise.all(processedEntries.map(async (entry, index) => {
    const timeText = getAllTimesText(
      entry,
      { showAbbreviatedDuration: false, returnComponent: false },
    );
    const additionalCollectedHeaderItems = constructTimecardPDFHeader(entry, t, timeText);
    const date = TaskHelpers.getTaskDate(entry) ?? DateTime.local();
    return constructCompletedFormPDF({
      employeeSignature: signatureData,
      useStandardTemplate: true,
      data: {
        sections: entry.customData,
      },
      formData: entry.formattedCustomData,
      fileMap,
      createdAt: date?.toMillis(),
      submitterName: name,
      employeeId,
      title: `Time Card for ${name}: ${date.toLocaleString(DateTime.DATE_MED)}`,
      settings: {
        ...settings,
        timezone: entry.timezone ?? settings.timezone,
        // ontraccr-form-to-pdf uses this to convert to a date text
      },
      projectIdMap,
      logo,
      drawOptions: [],
      shouldReturnPDF: true,
      additionalCollectedHeaderItems,
      collected: {
        employeeSignature: {
          // Only append the signature to the last page
          collect: signatureData && index === processedEntries.length - 1,
        },
        time: timeText,
        project: entry.projectNumber ? `${entry.projectNumber} - ${entry.projectName}` : entry.projectName,
        phase: entry.phase,
        costcode: entry.fullCostcode,
        startLocation: `${entry.startLatitude?.toFixed(2)}, ${entry.startLongitude?.toFixed(2)}`,
        endLocation: `${entry.endLatitude?.toFixed(2)}, ${entry.endLongitude?.toFixed(2)}`,
        className: entry.className,
      },
    });
  }));

  // Merge pdfs
  const mergedPDF = await PDFDocument.create();
  const pages = await Promise.all(pdfs.map(async (pdf) => (
    mergedPDF.copyPages(pdf, pdf.getPageIndices())
  )));

  // Add pages to merged pdf
  // We need to flatten the array of arrays to ensure order is maintained
  pages.forEach((copiedPages) => {
    copiedPages.forEach((page) => mergedPDF.addPage(page));
  });

  return mergedPDF.save();
};

export const getCCLabel = (cc) => `${cc.code} - ${cc.name}`;
export const getOpt = (item) => ({ label: item.name, value: item.id });

export const getProjectPhases = ({
  costcodes,
  phases,
  projectId,
}) => {
  const ccIdMap = getIdMap(costcodes);
  const unphasedCC = costcodes
    .filter(
      (cc) => cc.projectId === projectId
        && cc.phased === false,
    );
  const relevantPhases = phases
    .filter((phase) => phase.projectId === projectId);

  const phaseMap = {};
  if (unphasedCC.length > 0) {
    phaseMap[UNPHASED] = {
      id: UNPHASED,
      name: UNPHASED,
      costcodes: unphasedCC,
    };
  }
  relevantPhases.forEach((phase) => {
    const newPhase = { ...phase };
    delete newPhase.costcodeId;
    if (!(phase.id in phaseMap)) {
      phaseMap[phase.id] = {
        ...newPhase,
        costcodes: [],
      };
    }
    if (phase.costcodeId && phase.costcodeId in ccIdMap) {
      phaseMap[phase.id].costcodes.push(ccIdMap[phase.costcodeId]);
    }
  });
  return {
    phases: Object.values(phaseMap),
    phaseMap,
  };
};

export const getUserTeamProjects = ({
  user,
  teams,
  projectId,
}) => {
  const userTeamIds = new Set(user.teams?.map((team) => team.id));
  let projectsAcc = user.projects ?? [];
  teams.forEach((team) => {
    if (userTeamIds.has(team.id)) projectsAcc = [...projectsAcc, ...team.projects];
  });

  const projectSet = new Set(projectsAcc.map((project) => project.id));

  if (!projectSet.has(projectId)) projectSet.add(projectId);

  return projectSet;
};

export const getProjectOptions = ({
  activeProjects,
  teams,
  user,
  divisionIds = null,
  divisionId,
  projectId,
  settings,
}) => {
  const restrictProjectSelection = Permissions.has('RESTRICT_PROJECT_SELECTION') && Permissions.id === user.id;

  let divisionList = divisionIds;
  if (!divisionList && divisionId) {
    divisionList = [divisionId];
  } else {
    divisionList = [];
  }

  const divisionSet = new Set(divisionList);

  const projectOptions = activeProjects
    .filter((project) => (!divisionList.length || divisionSet.has(project.divisionId)))
    .map((project) => ({
      label: formatProjectLabelFromCompanySettings({ ...project, settings }),
      value: project.id,
      name: project.name,
      number: project.number,
    }));

  if (!restrictProjectSelection || !teams) return projectOptions;

  const userTeamProjects = getUserTeamProjects({
    user,
    teams,
    projectId,
  });

  const filteredProjects = projectOptions.filter((project) => (
    userTeamProjects.has(project.value)
  ));

  return filteredProjects;
};

export const getPhaseOptions = ({
  activeCostcodes,
  activePhases,
  projectId,
}) => {
  if (!projectId) return [];

  const {
    phases: parsedPhases,
  } = getProjectPhases({
    costcodes: activeCostcodes,
    phases: activePhases,
    projectId,
  });

  return parsedPhases.map(getOpt);
};

export const getCostcodeOptions = ({
  activeCostcodes,
  activePhases,
  projectId,
  phaseId,
}) => {
  let relevantCostcodes = [];

  const {
    phaseMap: phaseIdMap,
  } = getProjectPhases({
    costcodes: activeCostcodes,
    phases: activePhases,
    projectId,
  });

  if (!projectId) {
    relevantCostcodes = activeCostcodes.filter((cc) => !cc.projectId);
  } else {
    const {
      [phaseId]: {
        costcodes: phaseCC = [],
      } = {},
    } = phaseIdMap;

    relevantCostcodes = phaseCC;
  }

  return relevantCostcodes
    .filter((cc) => cc.category !== 'Material' && cc.category !== 'Equipment')
    .sort(sortByCode('code'))
    .map((cc) => ({ label: getCCLabel(cc), value: cc.id }));
};

export const getUserOptions = ({
  user,
  users,
  divisionId,
  userDivisions,
}) => {
  if (!divisionId) return [{ value: user.id, label: user.name }];

  const usersWithManualEntryPerms = users.filter((userObj) => (
    Permissions.has(`MANUAL_ENTRY_${Permissions.formatPosition(userObj.position)}`)
    && !!userDivisions?.[userObj.id]?.find((divId) => divId === divisionId)
    && userObj.active
  ));

  return usersWithManualEntryPerms.map((userObj) => ({ label: userObj.name, value: userObj.id }));
};

export const convertFromTimeBasedToHourBased = (values) => {
  const {
    timezone = DateTime.local().zoneName,
    date,
    startTime,
    endTime,
    breakStartTime,
    breakEndTime,
    otStartTime,
    otEndTime,
    doubleOTStartTime,
    doubleOTEndTime,
  } = values;
  if (!date) return values;
  const newValues = { ...values };

  const startOfDay = DateTime.fromISO(date, { zone: timezone }).startOf('day').toMillis();
  if (startTime && endTime) {
    newValues.startTime = startOfDay;
    newValues.endTime = startOfDay + (endTime - startTime);
  }

  if (breakStartTime && breakEndTime) {
    newValues.breakStartTime = startOfDay;
    newValues.breakEndTime = startOfDay + (breakEndTime - breakStartTime);
  }

  if (otStartTime && otEndTime) {
    newValues.otStartTime = startOfDay;
    newValues.otEndTime = startOfDay + (otEndTime - otStartTime);
  }

  if (doubleOTStartTime && doubleOTEndTime) {
    newValues.doubleOTStartTime = startOfDay;
    newValues.doubleOTEndTime = startOfDay + (doubleOTEndTime - doubleOTStartTime);
  }

  return newValues;
};

export const foundTaskConflict = ({
  editIds,
  currentTasks,
  newTasks,
  phases = [],
  projectIdMap = {},
  userMap = {},
  costcodeIdMap = {},
}) => {
  let conflictMessage;

  const isConflicted = TaskHelpers.hasTaskConflict({
    editIds,
    currentTasks,
    newTasks,
  });

  let phaseName = 'Unphased';
  if (isConflicted.phaseId) {
    phaseName = phases.find(({ id }) => id === isConflicted.phaseId)?.name ?? 'Unphased';
  }

  if (isConflicted) {
    const project = projectIdMap[isConflicted.projectId];
    const conflictedUser = userMap?.[isConflicted?.userId];
    const projectTitle = project
      ? `${project.name}-${project.number}`
      : 'None';
    const costcode = costcodeIdMap[isConflicted.costcodeId];
    const costcodeTitle = costcode ? `${costcode.code} - ${costcode.name}` : 'None';
    const date = TaskHelpers.getTaskDate(isConflicted);
    const timeText = getTimeText(isConflicted, { returnComponent: true });
    conflictMessage = (
      <p>
        Time conflict with existing entry:
        <br />
        <br />
        {`Date: ${date.toSQLDate()}`}
        <br />
        {`User: ${conflictedUser?.name}`}
        <br />
        {timeText}
        <br />
        {`Project: ${projectTitle}`}
        <br />
        {`Costcode: ${costcodeTitle}`}
        <br />
        {`Phase: ${phaseName}`}
        <br />
        {`Type: ${toTitleCase(isConflicted.type)}`}
      </p>
    );
  }
  return conflictMessage;
};

export const convertMillisHoursToMillisDate = (ms, date) => {
  if (!date?.isValid) return null;

  if (!ms) return null;

  const time = TaskHelpers.convertMillisecondsToHours(ms);

  const hours = Math.floor(time);
  const minutes = (time * 60) % 60;

  return date.set({ hours, minutes }).toMillis();
};
